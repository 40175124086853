import React from 'react';
import { StaticQuery, Link, navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBars } from 'react-icons/fa';

import styles from './topBar.module.scss';

const FullMenu = () => (
  <nav className={styles.navButtonsContainer}>
    <Link to="/" className={styles.navBtn} activeClassName={styles.active}>
      Home
    </Link>
    <Link to="/blog" className={styles.navBtn} activeClassName={styles.active} partiallyActive>
      Blog
    </Link>
    <Link to="/about" className={styles.navBtn} activeClassName={styles.active}>
      About
    </Link>
    <Link to="/contact" className={styles.navBtn} activeClassName={styles.active} partiallyActive>
      Contact
    </Link>
  </nav>
);

const CollapsedMenu = () => (
  <Dropdown drop="left">
    <Dropdown.Toggle id="collapsed-menu" type="button" aria-label="Collapsed Menu">
      <FaBars size={24} color="#656565" />
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item eventKey="1" onClick={() => navigate('/')}>
        Home
      </Dropdown.Item>
      <Dropdown.Item eventKey="1" onClick={() => navigate('/blog')}>
        Blog
      </Dropdown.Item>
      <Dropdown.Item eventKey="1" onClick={() => navigate('/about')}>
        About
      </Dropdown.Item>
      <Dropdown.Item eventKey="1" onClick={() => navigate('/contact')}>
        Contact
      </Dropdown.Item>
      {/*<Dropdown.Item eventKey="1" onClick={() => navigate('/how-to-help')}>*/}
      {/*  How to Help*/}
      {/*</Dropdown.Item>*/}
    </Dropdown.Menu>
  </Dropdown>
);

const TopBar = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logos/apj_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const { logo } = data;
      return (
        <header className={styles.topBarWrapper}>
          <Link to="/">
            <Img
              className={styles.logo}
              fluid={logo.childImageSharp.fluid}
              alt="Autism Parenting Journey"
              style={{ maxWidth: 300 }}
            />
          </Link>
          <div className={styles.fullMenuBar}>
            <FullMenu />
          </div>
          <div className={styles.collapsedMenu}>
            <CollapsedMenu />
          </div>
        </header>
      );
    }}
  />
);

export default TopBar;
