import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ title, description, image, pathname, article, is404 }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            defaultImage
            twitterMetaImage
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { defaultTitle, defaultDescription, siteUrl, defaultImage, twitterMetaImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || defaultImage,
        twitterImage: image || twitterMetaImage,
        url: `${siteUrl}${pathname || '/'}`,
        article,
      };

      return (
        <Helmet title={seo.title}>
          <html lang="en" />
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />
          {!is404 && <meta name="ROBOTS" content="INDEX, FOLLOW" />}
          {is404 && <meta name="robots" content="noindex" />}
          {/* FB/OPEN GRAPH TAGS */}
          <meta property="og:url" content={seo.url} />
          <meta property="og:type" content={seo.article ? 'article' : 'website'} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:site_name" content="Autism Parenting Journey" />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
          {/* TWIITER CARD */}
          <meta name="twitter:card" content="summary_large_image" />
          {/*  Twitter should use the og:url, og:title, og:description, and og:image for the card */}
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  is404: false,
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  is404: PropTypes.bool,
};

export default SEO;
