import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './footer.module.scss';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logos/leaf.png" }) {
          childImageSharp {
            fixed(width: 25, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const { logo } = data;
      return (
        <footer className={styles.footerWrapper}>
          <div className={styles.instaWrapper}>
            <p>
              <a
                href="https://www.instagram.com/autismparentingjourney/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @autismparentingjourney
              </a>
            </p>
          </div>

          <div className={styles.linkWrapper}>
            <Link to="/" className={styles.infoWrap}>
              <Img
                fixed={logo.childImageSharp.fixed}
                alt="Autism Parenting Journey"
                className={styles.logo}
              />
            </Link>
            <Link to="/blog" className={styles.link}>
              <p>Blog</p>
            </Link>
            <Link to="/about" className={styles.link}>
              <p>About</p>
            </Link>
            <Link to="/contact" className={styles.link}>
              <p>Contact</p>
            </Link>
          </div>
        </footer>
      );
    }}
  />
);

export default Footer;
